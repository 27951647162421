<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="goToList">文章管理</b-breadcrumb-item>
            <b-breadcrumb-item @click="goToList">文章列表</b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ isEdit ? " 文章編輯" : isReadOnly ? "文章檢視" : "新增文章" }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">
            {{ isEdit ? " 文章編輯" : isReadOnly ? "文章檢視" : "新增文章" }}
          </h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="標題"
              label-for="title"
            >
              <b-form-input
                id="title"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                :readonly="isReadOnly"
                v-model="form.title"
                :state="v$.form.title.$error ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.title.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="副標題"
              label-for="subtitle"
            >
              <b-form-input
                id="subtitle"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                :readonly="isReadOnly"
                v-model="form.subtitle"
              >
              </b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="主圖"
              label-for="subtitle"
            >
              <div class="image-selector">
                <div class="image" v-if="imageFile || form.image_url">
                  <img
                    class="img-fluid"
                    :src="imageFile != null ? imageFile : form.image_url"
                  />
                  <button
                    class="btn btn-danger"
                    type="button"
                    v-if="!isReadOnly"
                    @click="imageFile = form.image_url = null"
                  >
                    <i class="fa fa-times m-0" />
                  </button>
                </div>
                <div class="selector" v-else>
                  <ImageSelector
                    @input="selectImage"
                    class="
                      border
                      h-100
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <template #trigger="{ openFileSelector }">
                      <button
                        class="btn s-trigger-btn"
                        @click="openFileSelector"
                      >
                        <i class="fa fa-plus"></i> 圖片
                      </button>
                    </template>
                  </ImageSelector>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否顯示在首頁"
              label-for="type"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.show_main_page"
                :disabled="isReadOnly"
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="內文"
              label-for="content"
            >
              <ckeditor
                :editor="editor"
                :config="editorConfig"
                v-model="form.content"
                tag-name="textarea"
                :disabled="isReadOnly"
                class="form-control mb-2 mr-sm-2 mb-sm-0"
              ></ckeditor>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="發布時間"
              label-for="start_at"
            >
              <div class="row">
                <div class="col-12 col-xl-6">
                  <datepicker
                    placeholder="Select Date"
                    v-model="start_at.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabledDates="disabledDates"
                    :disabled="isReadOnly"
                  ></datepicker>
                </div>

                <div class="col-12 col-xl-6">
                  <vue-timepicker
                    format="HH:mm"
                    v-model="start_at.time"
                    :input-class="['form-control']"
                    :disabled="isReadOnly"
                  ></vue-timepicker>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="截止時間"
              label-for="end_at"
            >
              <div class="row">
                <div class="col-12 col-xl-6">
                  <datepicker
                    placeholder="Select Date"
                    v-model="end_at.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabledDates="disabledDates"
                    :disabled="isReadOnly"
                  ></datepicker>
                  <b-form-invalid-feedback
                    :state="!v$.start_at.date.beforeEndAt.$invalid"
                  >
                    結束時間必須大於起始時間
                  </b-form-invalid-feedback>
                </div>

                <div class="col-12 col-xl-6">
                  <vue-timepicker
                    format="HH:mm"
                    v-model="end_at.time"
                    :input-class="['form-control']"
                    :disabled="isReadOnly"
                  ></vue-timepicker>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="狀態"
              label-for="type"
            >
              <b-form-select
                id="status"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.status"
                :options="statusOptions"
                :disabled="isReadOnly"
              ></b-form-select>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="goToList"
          >取消</b-button
        >
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button variant="success" @click="handleSubmit">
            {{ isEdit ? "確認修改" : "確認新增" }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import postApi from "../../../apis/post";
import orgNameMixin from "@/mixins/organizationName";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import postStatus from "./postStatus";
import ImageSelector from "@/components/ImageSelector";
import imageMixin from "@/mixins/uploadImage";
import { format, isBefore, set, subDays, getHours, getMinutes } from "date-fns";
import imageApi from "@/apis/image";

export default {
  components: { Datepicker, VueTimepicker, ImageSelector },
  mixins: [orgNameMixin, imageMixin],
  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      form: {
        title: { required },
      },
      start_at: {
        date: {
          beforeEndAt: () => {
            if (!this.start_at.date || !this.end_at.date) {
              return true;
            }

            const startDate = set(new Date(this.start_at.date), {
              hours: this.start_at.time.HH,
              minutes: this.start_at.time.mm,
            });

            const endDate = set(new Date(this.end_at.date), {
              hours: this.end_at.time.HH,
              minutes: this.end_at.time.mm,
            });

            return isBefore(startDate, endDate);
          },
        },
      },
    };
  },

  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      start_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      end_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      disabledDates: { to: subDays(new Date(), 1) },
      form: {
        title: "",
        subtitle: "",
        content: "",
        show_main_page: false,
        start_at: undefined,
        end_at: undefined,
        status: 0,
        image_url: null,
      },
      statusOptions: Object.entries(postStatus).map(([text, value]) => ({
        value,
        text,
      })),
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [this.MyCustomUploadAdapterPlugin],
      },
      zh,
      imageFile: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("route", ["routeQuery"]),
    postId() {
      return this.$route.params.postId;
    },
    merchantId() {
      return this.$route.query.merchantId;
    },
    isEdit() {
      return this.$route.name === "PostEdit";
    },
    isReadOnly() {
      return this.$route.name === "PostView";
    },
  },
  mounted() {
    if (
      !this.checkPermissionAny([
        consts.POST_LIST_MODIFY,
        consts.POST_LIST_CREATE,
        consts.POST_LIST_VIEW,
      ])
    ) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.goToList();
        });
    } else if (!this.isEdit && !this.isReadOnly && !this.merchantId) {
      this.$swal
        .fire({
          type: "error",
          text: "未指定通路",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.goToList();
        });
    } else if (this.postId) {
      this.fetchPost();
    }
  },
  methods: {
    async fetchPost() {
      try {
        this.isLoading = true;
        const { data } = await postApi.getPost(this.postId);
        const {
          title,
          subtitle,
          content,
          show_main_page,
          start_at,
          end_at,
          status,
          image_url,
        } = data.data;

        if (start_at) {
          this.start_at = {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          };
        }
        if (end_at) {
          this.end_at = {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          };
        }
        this.form = {
          title,
          subtitle,
          content,
          show_main_page,
          status,
          image_url,
        };
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      this.formatDatetime("start_at");
      this.formatDatetime("end_at");

      try {
        this.isSubmmiting = true;

        if (this.imageFile) {
          const imageUrl = await this.uploadByBase64(this.imageFile);
          if (!imageUrl) {
            return;
          }
          this.form.image_url = imageUrl;
        }

        if (this.isEdit) {
          this.handleUpdate();
        } else {
          this.handleCreate();
        }
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      } finally {
        this.isSubmmiting = false;
      }
    },
    formatDatetime(key) {
      if (!this[key].date || !this[key].time.HH || !this[key].time.mm) return;

      this.form[key] = format(
        set(new Date(this[key].date), {
          hours: Number(this[key].time.HH),
          minutes: Number(this[key].time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    async handleUpdate() {
      await postApi.updatePost(
        this.postId,
        this.omitNullDate({
          ...this.form,
        })
      );
      this.showSuccessPopup();
    },
    async handleCreate() {
      await postApi.createPost(
        this.merchantId,
        this.omitNullDate({
          ...this.form,
        })
      );
      this.showSuccessPopup();
    },
    goToList() {
      this.$router.push({ name: "PostList", query: this.routeQuery });
    },
    showSuccessPopup() {
      this.$swal
        .fire({
          type: "success",
          text: this.isEdit ? "修改成功" : "新增成功",
        })
        .then(() => {
          this.goToList();
        });
    },
    checkPermissionAny(permissions) {
      const checker = new PermissionChecker();
      return checker.checkAny(permissions);
    },
    omitNullDate(form) {
      const result = { ...form };

      if (!result.start_at) {
        delete result.start_at;
      }
      if (!result.end_at) {
        delete result.end_at;
      }

      return result;
    },
    selectImage(image) {
      this.imageFile = image;
    },
    async MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              loader.file.then((file) => {
                // 呼叫圖片上傳 API
                imageApi.upload(file)
                  .then((response) => {
                    console.log(response);
                    // 確認 API 回傳的結構，並傳遞正確的圖片 URL
                    const imageUrl = response.data.data.image.url; // 假設後端返回 { url: '圖片的完整URL' }
                    resolve({ default: imageUrl });
                  })
                  .catch((error) => {
                    console.error('圖片上傳失敗', error);
                    reject(error);
                  });
              });
            });
          },
        };
      };
    }
  },
};
</script>

<style lang="scss">
.ck-editor__editable_inline {
  min-height: 10rem;
}

.image-selector {
  .image {
    position: relative;
    width: 30%;
    button {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
    }
  }
  .selector {
    width: 30%;
  }
}
</style>
